// src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import About from './components/about/About';
import Contact from './components/Contact/Contact';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Home from './components/home/Home';
import ScrollUp from './components/scrollup/ScrollUp';
import Services from './components/services/Services';
import Skills from './components/skills/Skills';
import Testimonials from './components/testimonials/Testimonials';
import HomeTitle from './components/home/homeTitle';
import Loader from './components/Loader'; // Import your Loader component

// Define the ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppRoutes() {
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Show loader on route change
    setLoading(true);

    // Simulate loading time, adjust as needed
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000); // Adjust the duration to match your loader animation duration

    return () => clearTimeout(timer);
  }, [location.pathname]); // Trigger effect on route change

  return (
    <>
      {loading && <Loader />} {/* Show loader while loading */}
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={
          <>
            <main className='main'>
              <Home />
              <About />
              <Skills />
              <Services />
              {/* <Qualification /> */}
              <Testimonials />
              <Contact />
              <HomeTitle />
            </main>
          </>
        } />

        <Route path="/about-me" element={<><About /> <Skills /></>} />
        <Route path="/services" element={<><Services /><Skills /></>} />
        <Route path="/testimonials" element={<><Testimonials /><Skills /></>} />
        <Route path="/contact-me" element={<><Contact /></>} />
      </Routes>
      <Footer />
      <ScrollUp />
    </>
  );
}

function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
