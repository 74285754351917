// src/components/Loader.js
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './Loader.css'; // Ensure this file contains appropriate styles

// Import the single loader image
import LoaderImage from '../assets/loader/Layer 1.png'; // Path to your single image

const Loader = () => {
  const containerRef = useRef(null);
  const imageRef = useRef(null);
  const textRef = useRef(null);

  useEffect(() => {
    // Ensure refs are set before applying animations
    if (!containerRef.current || !imageRef.current || !textRef.current) return;

    // GSAP timeline for complex sequencing
    const tl = gsap.timeline();

    tl.to(containerRef.current, {
      backgroundColor: 'rgba(255, 255, 255, 1)', // Black background
      opacity: 1,
      duration: 0.5,
      ease: 'power2.out'
    })
      .fromTo(
        imageRef.current,
        { opacity: 0, scale: 0.1 }, // Start very small
        { opacity: 1, scale: 0.75, duration: 1.5, ease: 'power2.out' } // End at 75% size
      )
      .fromTo(
        textRef.current.querySelectorAll('.letter'),
        { opacity: 0, scale: 0.1 },
        {
          opacity: 1,
          scale: 1,
          duration: 1.5,
          stagger: 0.1,
          ease: 'power2.out'
        },
        0 // Start text animation immediately
      )
      .to(
        containerRef.current,
        {
          opacity: 0, duration: 1, delay: 2, ease: 'power2.in', onComplete: () => {
            if (containerRef.current) {
              containerRef.current.classList.add('hidden');
            }
          }
        }
      )
      .to(
        imageRef.current,
        { opacity: 0, scale: 0.5, duration: 1, ease: 'power2.in' },
        "-=1" // Overlap with background fade
      )
      .to(
        textRef.current.querySelectorAll('.letter'),
        { opacity: 0, scale: 0.5, duration: 1, ease: 'power2.in' },
        "-=1" // Overlap with background fade
      );
  }, []);

  return (
    <div className="loader" ref={containerRef}>
      <img
        src={LoaderImage}
        alt="Loader"
        className="loader-image"
        ref={imageRef}
      />
      <div className="loader-text" ref={textRef}>
        {'abintjose.com'.split('').map((letter, index) => (
          <span key={index} className="letter">
            {letter}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Loader;
